<template>
  <div class="per-mod">
    <div class="list-box">
      <div class="title">
        <div class="text">近7日获得TOP10</div>
      </div>
      <div class="list-box">
        <div class="list-item" v-for="(item, index) in list" :key="index">
          <div class="good-box">
            <div class="good-img">
              <img
                v-if="item.cover"
                style="width: 100%; height: 75%; margin-top: 0.15rem"
                :src="item.cover"
                alt="" />
            </div>
          </div>
          <div class="price">{{ item.bean }}</div>
          <div class="name">{{ item.name }}</div>
          <div class="box-time">{{ item.created_at }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: [],
      sums: 0, //总价
    };
  },
  created() {
    this.grtdata();
  },
  methods: {
    // 计算总价
    grtdata() {
      this.data = this.list;
      var num = [];
      this.data.map((v) => {
        num.push(parseInt(v.box_bean));
      });
      var sum = num.reduce(function (a, b) {
        return a + b;
      }, 0);
      this.sums = sum;
    },
  },
};
</script>
<style lang="scss" scoped>
.per-mod {
  .list-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .title {
      font-size: 0.2rem;
      font-weight: 400;
      margin-bottom: 0.2rem;
    }

    .list-item {
      // margin-left: 0.1rem;
      width: 1.9rem;
      height: 2.68rem;
      background: url(../../assets/images/classics/falling-box.png) no-repeat;
      background-size: contain;
      overflow: hidden;
      box-sizing: border-box;
      padding-top: 0.36rem;
      text-align: center;
      font-size: 0.26rem;
      font-family: FZZongYi-M05S;
      font-weight: 400;
      color: #66ccff;
      margin-bottom: 0.2rem;
      margin-right: 0.1rem;

      &:nth-child(5n - 4) {
        margin-left: 0;
      }

      .good-box {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        position: relative;

        .good-img {
          width: 1.26rem;
          height: 1.26rem;
          // background: red;
          border-radius: 50%;
        }

        i {
          position: absolute;
          left: 0.55rem;
          bottom: 0.1rem;
        }
      }

      .price {
        // margin-top: 0.15rem;
        font-size: 0.27rem;
      }

      .name,
      .box-type {
        font-size: 0.15rem;
        font-family: Microsoft YaHei;
        height: 0.35rem;
        overflow: visible;
      }

      .box-type {
        margin-top: 0.1rem;
        color: #fff;
      }

      .box-time {
        font-size: 0.13rem;
        font-family: Microsoft YaHei;
        color: #fff;
      }

      .pic-name {
        margin-top: 0.09rem;
        width: 1.73rem;
        height: 0.32rem;
        background: #000000;
        border-radius: 0rem 0.11rem 0rem 0.11rem;
        font-size: 0.15rem;
        font-family: Microsoft YaHei;
        color: #cccccc;
        text-align: center;
        line-height: 0.32rem;
        box-sizing: border-box;
        border: 0.01rem solid #004672;
        box-shadow: inset 0px 0px 5px 1px #004672;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .pic {
          width: 0.16rem;
          height: 0.16rem;
          border-radius: 50%;
          background: #fff;
          margin-right: 0.12rem;
        }
      }
    }
  }
}
</style>
