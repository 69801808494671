<template>
  <div id="" class="public-footer-new">
    <div class="flex-c row-1">
      <div class="img-block">
        <img
          style="width: 100%"
          v-if="global_info.logo"
          :src="global_info.logo"
          alt=""
        />
      </div>
    </div>
    <div class="flex-c row-2">
      <div class="flex-c row-2-col row-2-col-1">
        <div class="common-text text">开箱</div>
        <div class="num">{{ onlineuserSum }}</div>
      </div>
      <div class="flex-c row-2-col row-2-col-2">
        <div class="common-text text">在线</div>
        <div class="num">{{ onlineCount }}</div>
      </div>
    </div>
    <div class="flex-c row-3">
      <div
        v-for="item in ArticleData"
        :key="item.id"
        @click="Toarticle(item)"
        class="row-3-text common-text"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="flex-c row-4">
      <div
        class="Copyright text-center"
        style="
          font-size: 0.14rem;
          color: #cfcfcf;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div style="display: flex; align-items: center; margin-right: 0.12rem">
          <img
            src="../../../assets/images/home/icplogo.png"
            style="width: 0.16rem; margin-right: 0.1rem"
            alt=""
          />
          <a
            style="color: #dfdfdf"
            href="https://beian.mps.gov.cn/#/query/webSearch?code=32058202011095"
            rel="noreferrer"
            target="_blank"
            >苏公网安备32058202011095</a
          >
        </div>
        <a
          style="color: #dfdfdf"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          >{{ website_icp }}</a
        >
        版权所有 ©2023-2024 tmskins.com All Rights Reserved<br />
      </div>
    </div>
 
  </div>
</template>

<script>
import { getArticleData } from "@/network/api.js";
import { mapState } from "vuex";
export default {
  name: "PublicFooter",
  data() {
    return {
      onlineCount: 400,
      onlineuserSum: 200, // 初始在线人数设定为1000
      ArticleData: [], //信息数据列表
      website_icp: "",
    };
  },

  created() {
    this.sumState();
    this.updateOnlineCount();
    this.getdata();
  },
  computed: {
    ...mapState(["global_info"]),
  },
  methods: {

    updateOnlineCount() {
      // 生成950到1050之间的随机整数
      const targetCount = Math.floor(Math.random() * (250 - 300 + 1)) +300;
      // 平滑变化在线人数
      this.smoothChange(this.onlineuserSum, targetCount, 3000); // 每三秒更新一次
    },
    smoothChange(current, target, duration) {
      const step = (target - current) / ((duration / 1000) * 60);
      const interval = setInterval(() => {
        current += step;
        this.onlineuserSum = Math.round(current);
        if (Math.abs(target - current) < Math.abs(step)) {
          clearInterval(interval);
          setTimeout(() => this.updateOnlineCount(), 3000); // 间隔三秒再次更新
        }
      }, 1000 / 60);
    },

    sumState() {
      // 使用定时器模拟在线人数的变化
      setInterval(() => {
        // 生成400到500之间的随机整数
        this.onlineCount = Math.floor(Math.random() * (500 - 400 + 1)) + 400;
        // 生成950到1050之间的随机整数
      }, 3000); // 每三秒更新一次
    },
    // 获得隐私信息列表
    getdata() {
      getArticleData().then((res) => {
        this.ArticleData = res.data.data;
      });
      this.website_icp = localStorage.getItem("website_icp");
    },
    Toarticle(v) {
      this.$router.push({
        path: "/Datapage",
        query: {
          id: v.id,
        },
      });
    },
    //查看规则
    Toxx(val) {
      this.$router.push(val);
    },
  },
};
</script>

<style lang="scss">
.public-footer-new {
  width: 100%;
  min-height: 2rem;
  background: #031024;
  padding: 0.2rem 0;
  .common-text {
    font-weight: 400;
    font-size: 0.16rem;
    color: #cfcfcf;
  }
  .row-1 {
    margin-bottom: 0.2rem;
    .img-block {
      width: 1.64rem;
      height: 0.4rem;
    }
  }
  .row-2 {
    margin-bottom: 0.11rem;
    .row-2-col {
      .text {
        margin-right: 0.2rem;
        white-space: nowrap;
      }
      .num {
        font-weight: 600;
        font-size: 0.2rem;
        color: #ffffff;
      }
    }
    .row-2-col-1 {
      margin-right: 0.32rem;
    }
  }
  .row-3 {
    margin-bottom: 0.33rem;
    .row-3-text {
      padding-right: 0.16rem;
      position: relative;
      cursor: pointer;
    }
    .row-3-text:nth-last-of-type(1)::after {
      display: none;
    }
    .row-3-text::after {
      position: absolute;
      content: "";
      top: 50%;
      right: 0.06rem;
      width: 0.02rem;
      height: 100%;
      transform: translateY(-50%);
      background: #cfcfcf;
    }
  }
  .row-4 {
  }
}

// .PublicFooter-sum {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   width: 3.8rem;
//   padding: 0 20px;

//   .info-num-top {
//     justify-content: space-between;
//   }

//   .info-num {
//     font-size: 0.35rem;
//     font-weight: bold;
//   }
// }

// .info-text {
//   color: #94989c;
//   font-size: 0.18rem;
//   line-height: 1.5;
//   position: relative;
//   padding-left: 0.1rem;

//   &::after {
//     position: absolute;
//     content: "";
//     top: 50%;
//     left: 0;
//     width: 0.07rem;
//     height: 0.07rem;
//     background: #94989c;
//     border-radius: 50%;
//   }
// }

// .footer-right {
//   width: 4.5rem;
//   position: absolute;
//   right: -1rem;
//   bottom: 0;

//   img {
//     width: 100%;
//   }
// }

// .PublicFooter {
//   background: #11141a;
//   // box-shadow: 0px 0px 10px 10px #011949;
// }

// #PublicFooter {
//   width: 100%;
//   padding-bottom: 0rem;
//   background-size: cover;

//   .main {
//     position: relative;
//     width: 14rem;
//     padding: 0.2rem 0;
//     margin-left: 1rem;

//     @media (max-width: 1000px) {
//       width: 90%;
//     }

//     .footer-logo {
//       width: 1.6rem;
//       // height: 0.25rem;
//       align-self: center;
//     }

//     .footer-rule {
//       width: 3rem;
//       text-align: left;
//       padding-left: 1rem;
//       line-height: 2;
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//       color: #94989c;
//       cursor: pointer;
//     }

//     .left {
//       width: 50%;
//       box-sizing: border-box;

//       p {
//         font-size: 0.2rem;
//         color: #fff;
//       }

//       .logo_Zbt {
//         width: 1.2rem;
//         height: 0.3rem;
//         margin-top: 0.2rem;

//         img {
//           width: 100%;
//           height: 100%;
//           vertical-align: middle;
//         }
//       }

//       .left_txt {
//         font-size: 0.18rem;
//         margin-top: 0.2rem;
//         color: #c6c6c6;
//         line-height: 1.7em;
//       }

//       .XY-box {
//         display: flex;
//         margin-top: 0.2rem;

//         p {
//           width: 1rem;
//           height: 0.25rem;
//           border-right: 1px solid #fff;
//           padding-left: 0.1rem;
//           box-sizing: border-box;
//           margin-left: -1px;
//           font-size: 0.18rem;
//           line-height: 0.25rem;
//           cursor: pointer;

//           &:hover {
//             color: #5b6bff;
//           }

//           &:first-child {
//             padding-left: 0;
//             width: 0.9rem;
//           }

//           &:last-child {
//             border-right: none;
//           }
//         }
//       }
//     }

//     .right {
//       box-sizing: border-box;

//       .right_logo {
//         text-align: center;

//         img {
//           height: 1rem;
//         }
//       }
//     }

//     .ZandW {
//       display: flex;
//       align-items: center;

//       a {
//         color: #ccc;
//         font-size: 12px;
//       }
//     }
//   }

//   .Copyright {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// }
</style>
