<template>
  <div class="highlight">
    <div class="player-tab">
      <div
        class="tab-item"
        :class="tabCurrent === tabIndex ? 'tab-item-ac' : ''"
        @click.stop="tabCurrentChange(tabIndex)"
        @tab="tabCurrentChange(tabIndex)"
        v-for="(tab, tabIndex) in popupTab"
        :key="tabIndex">
        {{ tab }}
      </div>
    </div>
    <div class="line2"></div>
    <div class="player-box">
      <div class="contentbox1" v-if="tabCurrent === 0">
        <div class="listcenter">
          <div
            class="listitem"
            v-for="(item, index) in performanceList"
            :key="index">
            <div class="lbox">
              <div class="timebox">{{ item.created_at }}</div>
              <div class="imgbox">
                <img
                  v-if="item.cover"
                  style="width: 100%"
                  :src="item.cover"
                  alt="" />
              </div>
            </div>
            <div class="rbox">
              <div class="boxname">{{ item.box_name }}</div>
              <div class="titlename">{{ item.name }}</div>
              <div class="pricebox">
                <!-- <img src="@/assets/images/about/user/zhaunshi.png" alt="" />
                {{ item.bean }} -->
              </div>
            </div>
            <div class="iconbox">
              <img
                v-show="index == 0"
                src="../../assets/images/NewUser/pc-newuser-topicon1.png"
                alt="" />
              <img
                v-show="index == 1"
                src="../../assets/images/NewUser/pc-newuser-topicon2.png"
                alt="" />
              <img
                v-show="index == 2"
                src="../../assets/images/NewUser/pc-newuser-topicon3.png"
                alt="" />
              <img
                v-show="index == 3"
                src="../../assets/images/NewUser/pc-newuser-topicon4.png"
                alt="" />
              <img
                v-show="index == 4"
                src="../../assets/images/NewUser/pc-newuser-topicon5.png"
                alt="" />
              <img
                v-show="index == 5"
                src="../../assets/images/NewUser/pc-newuser-topicon6.png"
                alt="" />
              <img
                v-show="index == 6"
                src="../../assets/images/NewUser/pc-newuser-topicon7.png"
                alt="" />
              <img
                v-show="index == 7"
                src="../../assets/images/NewUser/pc-newuser-topicon8.png"
                alt="" />
              <img
                v-show="index == 8"
                src="../../assets/images/NewUser/pc-newuser-topicon9.png"
                alt="" />
              <img
                v-show="index == 9"
                src="../../assets/images/NewUser/pc-newuser-topicon10.png"
                alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="contentbox1" v-if="tabCurrent === 1">
        <div style="display: flex; justify-content: space-around">
          <div class="itembox" v-for="(item, i) in gameList" :key="i">
            <div class="titlebox">
              <img
                v-if="item.type_name === '盲盒开箱'"
                src="../../assets/images/NewUser/pc-newuser-djitembg.png"
                class="type"
                alt="" />
              <img
                v-else
                src="../../assets/images/NewUser/pc-newuser-spitembg.png"
                class="type"
                alt="" />
            </div>
            <div class="goodsbox">
              <img style="width: 90%" :src="item.skins_img" alt="" />
            </div>
            <div class="infobox">
              <div class="price">
                <Cint :price="item.skins_price" />
              </div>
              <div class="infoname">{{ item.skins_name }}</div>
            </div>
            <div class="line"></div>
            <div class="priceinfobox">
              <div v-if="item.box_bean != ''">
                <div class="box-price">价格：{{ item.box_bean }}</div>
                <div class="time">{{ item.created_at }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from "./get";
import performance from "./performance";
import game from "./game";
import { mapState } from "vuex";
import {
  getNewlyGetApi,
  getNewlyHighLightApi,
  getPerformanceApi,
} from "@/network/api.js";
export default {
  components: {
    get,
    performance,
    game,
  },
  props: {
    // uuid: ''
  },
  data() {
    return {
      popupTab: ["最近获得", "高光时刻"],
      tabCurrent: 0,
      performanceList: [],
      getList: [],
      gameList: [
        {
          type_name: "盲盒开箱",
          skins_img: "",
          skins_price: "",
          skins_name: "",
          box_bean: "",
          created_at: "",
        },
      ],

      loading: false,
      page_num: 1, //页码
      pageSize: 10, //每页显示
      total: 0, //总数
    };
  },
  computed: {
    ...mapState(["popupHighLightId"]),
  },
  created() {
    this.getPerformance();
    this.getNewlyHighLight();
  },
  methods: {
    // 最近获得
    getNewlyGet() {
      getNewlyGetApi({ uid: this.popupHighLightId }, this.page_num).then(
        (res) => {
          this.getList = res.data.data.data;
          this.pageSize = res.data.data.per_page;
          this.total = res.data.data.total;
        }
      );
    },
    //最近获得分页
    listchange(v) {
      this.page_num = v.page;
      this.getNewlyGet();
    },
    // 盲盒游戏
    getNewlyHighLight() {
      getNewlyHighLightApi({ uid: this.popupHighLightId }).then((res) => {
        this.gameList = this.gameList.map((item, index) => {
          let retItem = item;
          res.data.data.forEach((element) => {
            if (element.type_name === item.type_name) {
              retItem = element;
            }
          });
          return retItem;
        });
      });
    },
    // 近期表现
    getPerformance() {
      getPerformanceApi({ uid: this.popupHighLightId }).then((res) => {
        this.performanceList = res.data.data;
      });
    },
    tabCurrentChange(i) {
      this.tabCurrent = i;
      this.loading = true;
      if (i === 0) {
        this.getPerformance();
      } else if (i === 1) {
        this.getNewlyHighLight();
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.highlight {
  width: 7.64rem;
  height: 7.2rem;
  background: #08080f;
  border: 2px solid #434375;
}

.player-tab {
  position: relative;
  display: flex;
  width: 100%;
  height: 0.5rem;
  align-items: center;
  .tab-item {
    position: relative;
    z-index: 2;
    width: 1.98rem;
    height: 0.58rem;
    font-size: 0.2rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #48485e;
    line-height: 0.65rem;
    text-align: center;
    cursor: pointer;
  }

  .tab-item-ac {
    color: #9292d7;
  }
}
.line2 {
  width: 100%;
  height: 0;
  border: 0.01rem solid;
  border-image: linear-gradient(
      270deg,
      rgba(67, 67, 117, 0),
      rgba(67, 67, 117, 1)
    )
    1 1;
}

.player-box {
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.15rem;

  .contentbox1 {
    width: 100%;
    height: 6.37rem;
    background: #08080f;
    .listcenter {
      width: 7.32rem;
      margin: 0.05rem auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .listitem {
        width: 3.61rem;
        height: 1.19rem;
        background: #101022;
        border: 0.02rem solid #201e43;
        margin-bottom: 0.1rem;
        border-radius: 0.04rem;
        display: flex;
        justify-content: space-between;
        position: relative;

        .lbox {
          width: 40%;
          margin: 0.12rem 0 0.12rem 0.35rem;
          .timebox {
            font-size: 0.12rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #606085;
          }
          .imgbox {
            width: 1.06rem;
            height: 0.8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url("../../assets/images/NewUser/pc-newuser-itemboxbg.png")
              no-repeat;
            background-size: 100% 100%;
          }
        }
        .rbox {
          width: 45%;
          margin-top: 0.25rem;
          z-index: 5;
          .boxname {
            font-size: 0.16rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #516bf3;
            margin-bottom: 0.1rem;
          }
          .titlename {
            font-size: 0.14rem;
            height: 0.33rem;
            line-height: 0.16rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 控制行数 */
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
          }
          .pricebox {
            display: flex;
            align-items: center;
            font-size: 0.14rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #efa900;
            img {
              width: 0.17rem;
              margin-right: 0.12rem;
            }
          }
        }
        .iconbox {
          position: absolute;
          top: 0;
          right: 0;
          img {
            width: 0.94rem;
            height: 0.9rem;
            z-index: 2;
          }
        }
      }
    }

    .itembox {
      width: 2.32rem;
      height: 6.4rem;
      background: #111121;
      border-radius: 0.04rem;

      .titlebox {
        width: 100%;
        height: 0.4rem;
        margin-top: 0.57rem;
        display: flex;
        justify-content: end;
        background: #141428;
        align-items: center;
        img {
          width: 1.71rem;
          height: 0.42rem;
          margin-right: 0.2rem;
        }
      }
      .goodsbox {
        width: 1.62rem;
        height: 1.74rem;
        background: url("../../assets/images/NewUser/pc-newuser-goodsimgbg.png")
          no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.93rem auto 0.37rem;
      }
      .infobox {
        height: 0.9rem;
        text-align: center;
        .infoname {
          height: 0.6rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 0.2rem;
          color: #b1b1da;
          padding: 0.15rem 0.1rem 0;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .line {
        width: 100%;
        height: 0px;
        border: 0.01rem solid #535373;
        margin: 0 auto;
      }
      .priceinfobox {
        width: 100%;
        height: 1.5rem;
        background: linear-gradient(
          180deg,
          rgba(20, 20, 40, 0) 0%,
          #1b1b37 100%
        );
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 0.2rem;
        color: #b1b1da;
        .box-price {
          width: 100%;
          margin-bottom: 0.2rem;
        }
        .time {
          width: 100%;
        }
      }
    }
  }
}
</style>
