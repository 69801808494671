<template>
  <div id="SwiperBox">
    <div class="swiper-box">
      <div class="swiper-left">
        <div
          @click="changeStatusOnClick"
          class="left-bg1 point"
          :class="{ 'left-bg2': isAllStatus }"></div>
      </div>
      <div class="swiper-box-new-wrap">
        <div
          ref="topWrapBox"
          class="swiper-box-new"
          :class="{ 'swiper-box-new-transition': isAnimationRun }">
          <div
            @click="swiperItemOnClick(item)"
            v-for="(item, index) in [...carouselArr12, ...carouselArr]"
            :key="`${item.id}${index}`"
            class="swiper-item-wrap">
            <div :href="item | RouteLink">
              <div
                class="swiper-item"
                :class="
                  item.lv != 0 ? `lv${item.lv}` : item.bean > 1 ? 'lv1' : 'lv4'
                "
                dir="ltr">
                <div class="batlle" v-show="item.type == 3">
                  <img src="@/assets/images/NavHeader/battle.png" alt="" />
                </div>
                <div class="batlle" v-show="item.type == 1">
                  <img src="@/assets/images/NavHeader/openbox.png" alt="" />
                </div>
                <div class="batlle" v-show="item.type == 4">
                  <img src="@/assets/images/NavHeader/lucky.png" alt="" />
                </div>
                <div class="batlle" v-show="item.type == 7">
                  <img src="@/assets/images/NavHeader/ronglian.png" alt="" />
                </div>
                <div class="batlle" v-show="item.type == 9">
                  <!-- 弹药 -->
                  <img src="@/assets/images/about/user/zidan.png" alt="" />
                </div>
                <div class="batlle" v-show="item.type == 2">
                  <img src="@/assets/images/about/user/pointicon.png" alt="" />
                </div>
                <div class="left">
                  <div
                    class="pic"
                    :style="{
                      'background-image':
                        'url(' + item.lv_bg_image != null + ')',
                    }">
                    <img :src="item.cover" alt="" />
                  </div>
                  <div class="data">
                    <span class="goodsname" dir="ltr">{{
                      item.name | GuoLvOne
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div :href="item | RouteLink" class="user-swiper">
              <div
                class="swiper-item"
                :class="
                  item.lv != 0 ? `lv${item.lv}` : item.bean > 1 ? 'lv1' : 'lv4'
                "
                dir="ltr">
                <div class="batlle" v-show="item.type == 3">
                  <img src="@/assets/images/NavHeader/battle.png" alt="" />
                </div>
                <div class="batlle" v-show="item.type == 1">
                  <img src="@/assets/images/NavHeader/openbox.png" alt="" />
                </div>
                <div class="batlle" v-show="item.type == 4">
                  <img src="@/assets/images/NavHeader/lucky.png" alt="" />
                </div>
                <div class="batlle" v-show="item.type == 7">
                  <img src="@/assets/images/NavHeader/ronglian.png" alt="" />
                </div>
                <div class="batlle" v-show="item.type == 9">
                  <img src="@/assets/images/about/user/zidan.png" alt="" />
                </div>
                <div class="batlle" v-show="item.type == 2">
                  <img src="@/assets/images/about/user/pointicon.png" alt="" />
                </div>
                <div class="left">
                  <div
                    class="pic user_pic"
                    :style="{
                      'background-image':
                        'url(' + item.lv_bg_image != null + ')',
                    }">
                    <img
                      style="width: 70%; height: 70%"
                      :src="item.get_user?.avatar"
                      alt="" />
                  </div>
                  <div
                    class="data"
                    style="text-align: center; font-size: 0.16rem">
                    <div dir="ltr">{{ item.get_user?.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { UnpackingRecord } from "@/network/api.js";
export default {
  name: "SwiperBox",
  data() {
    return {
      // 轮播上边
      transNum: 3,
      carouselArr: [],
      carouselArr12: [],
      isAnimationRun: true,
      fiveCarouselArr: [],
      loadingShow: true,
      isAllStatus: false,
      swiperOption: {
        loop: true,
        // 自动轮播
        autoplay: {
          delay: 3000000,
          //当用户滑动图片后继续自动轮播
          disableOnInteraction: false,
        },
        speed: 1000,
        simulateTouch: false,
        spaceBetween: 0,
        //开启循环模式
        // loop: true,
        slidesPerView: 1,
        centeredSlides: true,
        centeredSlidesBounds: true,
      },
      tempArr: [],
      Timer: "",
      xLeft: 0,
    };
  },

  filters: {
    LvGuoLv(val) {
      if (val == "1") {
        return "lv1";
      } else if (val == "2") {
        return "lv2";
      } else if (val == "3") {
        return "lv3";
      } else if (val == "4") {
        return "lv4";
      } else if (val == "5") {
        return "lv4";
      }
    },

    RouteLink(item) {
      // 有8种可能，但是目前只用到了三种
      if (item.type == "3") {
        return "/Battle";
      } else if (item.type == "4") {
        if (item.bean > 1) {
          return "/Lucky_open?id=" + item.skin_id;
        } else {
          return "/Lucky";
        }
      } else if (item.type == "7") {
        return "/fusion";
      } else {
        return "/OpeningBox?id=" + item.box_id;
      }
    },
    GuoLvOne(val) {
      return val.length > 12 ? val.substr(0, 10) + "..." : val;
    },
  },
  created() {
    this.GetUnpackingRecord(true);
  },
  mounted() {
    const elem = this.$refs.topWrapBox;
    elem.addEventListener("animationend", () => {
      this.carouselArr12 = JSON.parse(
        JSON.stringify(this.carouselArr.slice(28, 40))
      );
      this.isAnimationRun = false;
      console.log("Animation ended.");
      const params = {
        type: this.isAllStatus ? 1 : 2,
      };
      UnpackingRecord(params)
        .then((res) => {
          setTimeout(() => {
            this.carouselArr = res.data.data;
            this.isAnimationRun = true;
          }, 0);
        })
        .catch((err) => {
          this.isAnimationRun = true;
        });
    });
  },
  methods: {
    changeStatusOnClick() {
      this.isAllStatus = !this.isAllStatus;
      this.GetUnpackingRecord();
    },
    swiperItemOnClick(item) {
      // 有8种可能，但是目前只用到了三种
      let routerUrl = "";
      if (item.type == "3") {
        routerUrl = "/Battle";
      } else if (item.type == "4") {
        if (item.bean > 1) {
          routerUrl = "/Lucky_open?id=" + item.skin_id;
        } else {
          routerUrl = "/Lucky";
        }
      } else if (item.type == "7") {
        routerUrl = "/fusion";
      } else if (item.type == "10") {
        routerUrl = `/OpeningBox?id=${item.box_id}&typeid=13`;
      } else {
        routerUrl = "/OpeningBox?id=" + item.box_id;
      }
      this.$router.push(routerUrl);
    },
    //开奖记录
    GetUnpackingRecord() {
      const params = {
        type: this.isAllStatus ? 1 : 2,
      };
      UnpackingRecord(params).then((res) => {
        this.carouselArr = res.data.data;
        this.carouselArr12 = JSON.parse(
          JSON.stringify(this.carouselArr.slice(28, 40))
        );
      });
    },
  },

  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style lang="scss">
#SwiperBox {
  background: #0f2756;
  .swiper-wrapper-box {
    height: 1.5rem;
  }
  .user_pic {
    border-radius: 50%;
    overflow: hidden;
    img {
      border-radius: 50%;
    }
  }
  width: 100%;
  position: relative;
  z-index: 10;
  overflow: hidden;

  .swiper-box {
    width: 100%;
    position: relative;
    height: 1.5rem;
    display: flex;

    .vant-load {
      text-align: center;
      line-height: 1.4rem;
    }

    .swiper-left {
      top: 0;
      left: 0;
      width: 0.82rem;
      height: 100%;
      z-index: 2;
      flex-shrink: 0;
      .left-bg1 {
        background: red;
        height: 100%;
        background: url(../../../assets/images/NavHeader/nav-bg1.png) no-repeat
          center;
        background-size: 100% 100%;
      }
      .left-bg2 {
        background: url(../../../assets/images/NavHeader/nav-bg2.png) no-repeat
          center;
        background-size: 100% 100%;
      }
    }
    .swiper-box-new-wrap {
      width: 100%;
      height: 100%;
      position: relative;
    }
    /* 定义动画 */
    @keyframes growAndShrink {
      0% {
        transform: translateX(0rem);
      }
      100% {
        transform: translateX(-60rem);
      }
    }
    .swiper-box-new {
      // will-change: transform;
      width: 100%;
      height: 100%;
      display: flex;
      position: absolute;
      right: 0rem;
      top: 0;
      cursor: pointer;
      &:hover {
        animation-play-state: paused;
      }
      // flex-direction: row-reverse;
      .swiper-item-wrap {
        position: relative;
        width: 1.5rem;
        overflow: hidden;
        flex-shrink: 0;
        height: 1.5rem;
        box-sizing: border-box;
        &:hover {
          & .user-swiper {
            border: 0 !important;
            opacity: 0.9;
            width: 100%;
            transform: rotateY(0deg);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            text-align: center;
            cursor: pointer;
          }
        }
        .swiper-item {
          width: 100%;
          height: 1.5rem;
          position: relative;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          color: #fff;
          &.lv1 {
            background: url(../../../assets/images/public/back_pub-new1.png)
              no-repeat center;
            background-size: 100% 100%;
          }

          &.lv2 {
            background: url(../../../assets/images/public/back_pub-new2.png)
              no-repeat center;
            background-size: 100% 100%;
          }

          &.lv3 {
            background: url(../../../assets/images/public/back_pub-new3.png)
              no-repeat center;
            background-size: 100% 100%;
          }

          &.lv4 {
            background: url(../../../assets/images/public/back_pub-new4.png)
              no-repeat center;
            background-size: 100% 100%;
          }
          .batlle {
            width: 0.15rem;
            position: absolute;
            right: 0.16rem;
            top: 0.08rem;

            img {
              width: 100%;
            }
          }

          .profit_ratio {
            width: 70px;
            position: absolute;
            right: 0.3rem;
            top: 5px;
            font-size: 8px;
          }

          .left {
            height: 100%;
            width: 100%;
            overflow: hidden;
            .pic {
              width: 1.1rem;
              height: 1.1rem;
              background-repeat: no-repeat;
              background-size: 70%;
              background-position: center;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 0.1rem;
              margin-left: 0.2rem;

              img {
                max-width: 100%;
                max-height: 100%;
              }
            }

            .data {
              text-align: center;

              p {
                width: 0.8rem;
                font-size: 0.2rem;
                margin-top: 0.18rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #d42d4f;
              }

              span {
                display: block;
                margin-top: 0.02rem;
                width: 90%;
                font-size: 0.14rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 0 auto;
              }
            }
          }

          .right {
            width: 1.1rem;
            text-align: center;
            padding-top: 0.1rem;
            padding-right: 0.1rem;
            box-sizing: border-box;

            img {
              width: 0.3rem;
              height: 0.3rem;
              border-radius: 50%;
              box-sizing: border-box;
            }

            p {
              font-size: 0.12rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .swiper-box-new-transition {
      animation: growAndShrink 60s linear forwards;
    }
  }
}

.user-swiper {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: rotateY(180deg);
  transform-origin: 50% 0;
  transition: all 0.5s;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
</style>
